'use strict'
const _ = require('lodash')
const {VARIANTS_CSS_STRATEGIES} = require('../../utils/constants')
const {constants} = require('santa-core-utils')
const {displayedOnlyStructureUtil} = require('santa-core-utils')

const transformKeys = ['scale', 'rotate', 'translate', 'skew']

const isPropertyDefined = (property, styleItem) => styleItem.hasOwnProperty(property) && styleItem[property] !== undefined && styleItem[property] !== null

const omitUndefinedProperties = styleItem => _.pickBy(styleItem, (__, property) => isPropertyDefined(property, styleItem))

const getSelectorByVariant = variant => {
    switch (variant.type) {
        case 'Hover':
            return ':hover'
        case 'Class':
            return `.${variant.id}`
        default:
            // no matching type, throwing
            throw new Error('Variant condition does not exist', variant.type)
    }
}
const getAdditionalPrefixes = (prefixes, suffix) => {
    if (!prefixes || !prefixes.length) {
        return ''
    }
    return prefixes.map(prefix => `,${prefix}${suffix}`)
}

const getUnit = unit => {
    if (unit === 'percentage') {
        return '%'
    }
    return unit
}

const resolveValueWithUnit = valueWithUnit => valueWithUnit ? `${valueWithUnit.value}${getUnit(valueWithUnit.type)}` : 0

const resolveValue = value => value || 0

const isStyleItemContainsTransformProps = styleItem => transformKeys.some(property => isPropertyDefined(property, styleItem))

const applyCSSToChildren = (rule, strategy) =>
    strategy === VARIANTS_CSS_STRATEGIES.ALL_CHILDREN ? `${rule}, ${rule} *` : `${rule}, ${rule} > *`

const getTranslateString = translate => {
    let translateString = ''
    if (!translate) {
        return translateString
    }
    const {x: translateX, y: translateY} = translate
    if (translateX) {
        translateString += `translateX(${resolveValueWithUnit(translateX)}) `
    }
    if (translateY) {
        translateString += `translateY(${resolveValueWithUnit(translateY)}) `
    }
    return translateString
}

const getScaleString = scale => {
    let scaleString = ''
    if (!scale) {
        return scaleString
    }
    const {x: scaleX, y: scaleY} = scale
    if (scaleX) {
        scaleString += `scaleX(${resolveValue(scaleX)}) `
    }
    if (scaleY) {
        scaleString += `scaleY(${resolveValue(scaleY)}) `
    }
    return scaleString
}

const getRotateString = (rotate, componentDefaultRotation) => {
    let rotateString = ''

    if (rotate || componentDefaultRotation) {
        const compRotation = componentDefaultRotation || 0
        const variantRotation = rotate || 0
        rotateString += `rotate(${resolveValue(variantRotation) + compRotation}deg) `
    }
    return rotateString
}

const getSkewString = skew => {
    let skewString = ''
    if (!skew) {
        return skewString
    }
    const {x: skewX, y: skewY} = skew
    if (skewX) {
        skewString += `skewX(${resolveValue(skewX)}deg) `
    }
    if (skewY) {
        skewString += `skewY(${resolveValue(skewY)}deg) `
    }
    return skewString
}

const fixDisplayedOnlyCompId = (compIdToFix, containerId) => {
    const itemId = displayedOnlyStructureUtil.getRepeaterItemId(containerId)
    if (itemId) {
        return displayedOnlyStructureUtil.getUniqueDisplayedId(compIdToFix, itemId)
    }
    return compIdToFix
}

module.exports = {
    functionLibrary: {
        getCSSRules: (cssString, selectors, isComponentStyle, defaultPrefixes) => isComponentStyle ?
            // eslint-disable-next-line no-control-regex
            cssString.replace(new RegExp('([^\r\n,{}]+)(,(?=[^}]*{)|\s*{)', 'g'), rule => {
                let cssRule = rule.trim()
                const lastChar = cssRule.charAt(cssRule.length - 1)
                cssRule = lastChar === '{' || lastChar === ',' ? cssRule.slice(0, -1) : cssRule
                const res = `${selectors}${cssRule}, ${selectors} ${cssRule}${getAdditionalPrefixes(defaultPrefixes, `${cssRule}`)}${getAdditionalPrefixes(defaultPrefixes, ` ${cssRule}`)}`
                return `${res}${lastChar}`
            }) : `${selectors}${getAdditionalPrefixes(defaultPrefixes, '')} ${cssString}`,

        getCSSFromTransformationItem: (styleItem, componentDefaultRotation) => {
            const css = []
            const filteredStyleItem = omitUndefinedProperties(styleItem)
            if (filteredStyleItem.zIndex) {
                css.push(`z-index: ${filteredStyleItem.zIndex} `)
            }
            if (filteredStyleItem.hasOwnProperty('hidden')) {
                css.push(`opacity: ${filteredStyleItem.hidden ? 0 : 1} `)
                css.push(`visibility: ${filteredStyleItem.hidden ? 'hidden' : 'visible'} `)
            }
            if (filteredStyleItem.origin) {
                css.push(`transform-origin: ${resolveValueWithUnit(filteredStyleItem.origin.x)} ${resolveValueWithUnit(filteredStyleItem.origin.y)} `)
            }
            if (filteredStyleItem.filter) {
                const {blur = 0, opacity = 100, grayscale = 0} = filteredStyleItem.filter
                css.push(`filter: opacity(${opacity}%) grayscale(${grayscale}%) blur(${blur}px) `)
            }
            if (isStyleItemContainsTransformProps(filteredStyleItem)) {
                const {TRANSFORMATIONS_ORDER} = constants
                const {scale, rotate, translate, skew} = filteredStyleItem

                const transformationsString = {
                    translate: getTranslateString(translate),
                    scale: getScaleString(scale),
                    rotate: getRotateString(rotate, componentDefaultRotation),
                    skew: getSkewString(skew)
                }

                const transformStringValue = TRANSFORMATIONS_ORDER.reduce((transformString, transformKey) =>
                    `${transformString}${transformationsString[transformKey]}`, '').trim()
                if (transformStringValue) {
                    const transformString = `transform: ${transformStringValue}`
                    css.push(transformString)
                }
            }

            return css.length ? `{ ${css.join('; ')}}` : ''
        },
        getCSSFromTransitionItem: styleItem => {
            const {property = 'all', timingFunction = 'ease', duration = 0, delay = 0} = styleItem
            return `{ transition: ${property} ${duration}s ${timingFunction} ${delay}s }`
        },
        getSelectorsForStyleChanges: (variants, compId) => {
            let variantCompId = fixDisplayedOnlyCompId(variants[0].componentId, compId)
            let selectors = variantCompId === compId ? `[id^="${variantCompId}"]` : `#${variantCompId}`
            let currentsSource = variantCompId
            variants.forEach(variant => {
                variantCompId = fixDisplayedOnlyCompId(variant.componentId, compId)
                const selector = getSelectorByVariant(variant)
                if (currentsSource !== variantCompId) {
                    if (variantCompId === compId) {
                        selectors += ` [id^="${variantCompId}"]`
                    } else {
                        selectors += ` #${variantCompId}`
                    }
                    currentsSource = variantCompId
                }
                selectors += selector
            })

            if (currentsSource !== compId) {
                selectors += ` [id^="${compId}"]`
            }
            return selectors
        },
        getSelectorsForEffects: (variants, compId, applyToChildrenStrategy) => {
            let currentsSource = fixDisplayedOnlyCompId(variants[0].componentId, compId)
            let selectors = `#${currentsSource}`
            variants.forEach(variant => {
                const variantCompId = fixDisplayedOnlyCompId(variant.componentId, compId)
                const selector = getSelectorByVariant(variant)
                if (currentsSource !== variantCompId) {
                    if (variantCompId === compId) {
                        selectors += ` #${variantCompId}`
                    } else {
                        selectors += ` #${variantCompId}`
                    }
                    currentsSource = variantCompId
                }
                selectors += selector
            })

            if (currentsSource !== compId) {
                selectors += ` #${compId}`
            }
            selectors = applyToChildrenStrategy ? applyCSSToChildren(selectors, applyToChildrenStrategy) : selectors
            return `${selectors}`
        },
        applyRuleToChildren: (cssRule, applyToChildrenStrategy) => cssRule.split(',')
            .map(s => s.trim())
            .map(css => applyCSSToChildren(css, applyToChildrenStrategy))
            .join()
    }
}
